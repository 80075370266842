<template>
    <div class="bottom-block--wrapper">
        <div class="bottom-block--text" v-if="showContent && text">
            <div v-html="text"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: "BottomStoryBlock",
    props: {
        type: {
            type: String,
            required: true
        },
        slug: {
            type: String,
        },
        text: {
            type: String,
        },
        showContent: {
            type: Boolean,
            default: true,
        },
        tags: {
            type: [Array, Object]
        }
    }
}
</script>

<style scoped lang="scss">
    .bottom-block {
        &--wrapper {
            position: fixed;
            bottom: 1em;
            left: 0.5em;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
            z-index: 101;
        }

        &--tag {
            z-index: 2;
            font-size: 15px;
            color: #fff;
            font-weight: bold;
            padding: 8px 12px;

            & span {
                border: 1px solid rgba(255,255,255,0.3);
                padding: 6px 10px;
                border-radius: 20px;
                text-shadow: 1px 1px rgba(0,0,0,0.4);
            }
        }

        &--text {
            display: inline-flex;
            padding: 8px 12px;
            line-height: 16px;
            border-radius: 16px;
            color: #fff;
            text-shadow: 1px 1px rgba(0,0,0,0.4);

            & >>> .hashtag {
                font-weight: bold;
            }
        }
    }
</style>

<style lang="scss">
.hashtag {
    font-weight: bold;
}
</style>
